import CookieModal from "./CookieModal";
import { useEffect, useState } from "react";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    if (!localStorage.getItem("THIS_IS_COOKIEEEEE")) {
      //COOOOOKIEEEEEEES
      setIsVisible(true);
    }
  }, []);

  return (
    <>
      <footer>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="footer_pushup">
                <h2>Začněte prodávat s ENIGOO ještě dnes</h2>
                <p>
                  ENIGOO vám nabídne spolehlivý a rychlý systém k prodeji
                  vstupenek a zabezpečení vaší akce. Neustále rozvíjicí se
                  systém se vám do budoucna vyplatí.
                </p>
                <div className="mt-4">
                  <a
                    href="https://www.enigoo.cz/kontakt/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-outline-light"
                  >
                    Chci domluvit schůzku
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="footer_groups">
                    <div className="row">
                      <div className="col-lg-3 d-flex align-items-center">
                        <div className="footer_group_copyright">
                          <img
                            src="https://enigoo.cz/front-module/img/enigoo.svg"
                            className="img-fluid"
                            alt=""
                          />
                          <div className="flex justify-center text-center text-sm">
                            <div>© 2025 ENIGOO s.r.o.</div>
                            Hlaváčova 207, 530 02 Pardubice <br />
                            IČO O8750823 DIČ CZ08750823 <br />
                            info@enigoo.cz <br />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <div className="footer_group">
                          <p>Navigace</p>
                          <ul>
                            <li>
                              <a href="/">Vstupenky</a>
                            </li>
                            <li>
                              <a
                                href="https://enigoo.cz/"
                                target="_blank"
                                rel="noopener noreferrer">
                                ENIGOO
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-3">
                        <div className="footer_group">
                          <p>ENIGOO</p>
                          <ul>
                            <li>
                              <a
                                href="https://enigoo.cz/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                ENIGOO
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://enigoo.cz/assets/dokumenty/ENIGOO_OP.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Obchodní podmínky
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://enigoo.cz/assets/dokumenty/ENIGOO_GDPR.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Souhlas s GDPR
                              </a>
                            </li>
                            <li>
                              <a
                                href={"/"}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setIsVisible(true);
                                  localStorage.clear();
                                }}
                              >
                                Předvolby cookies
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 8,
                    }}
                  >
                    <div className="footer_soc">
                      <a
                        href="https://www.facebook.com/enigoo/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fab"
                          data-icon="facebook-square"
                          className="svg-inline--fa fa-facebook-square fa-w-14 "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                          ></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.instagram.com/enigoo.cz/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fab"
                          data-icon="instagram"
                          className="svg-inline--fa fa-instagram fa-w-14 "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                          ></path>
                        </svg>
                      </a>
                      <a
                        href="https://www.linkedin.com/company/enigoo/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fab"
                          data-icon="linkedin-in"
                          className="svg-inline--fa fa-linkedin-in fa-w-14 "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            fill="currentColor"
                            d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                          ></path>
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {isVisible && (
        <div className={isVisible ? "no-scroll" : ""}>
          <CookieModal setIsVisible={setIsVisible} />
        </div>
      )}
    </>
  );
};
export default Footer;
