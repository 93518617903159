import React, {useEffect} from 'react';
import './CookieModal.css';

function CookieModal({setIsVisible}) {

    const handleClose = (e) => {
        setIsVisible(false);
        localStorage.setItem("THIS_IS_COOKIEEEEE", true)
    }

    useEffect(() => {
        document.body.classList.add("no-scroll")
        return()=>{
            document.body.classList.remove("no-scroll")
        }
    }, []);

    return (
        (
            <div className="modal-container">
                <div className="modal-content">
                    <h3>Předvolby souborů cookie</h3>
                    <p>
                    Soubory cookie používáme k zajištění základních funkcí webu a ke zlepšení vašeho zážitku.
                    </p>
                    <div className="options">
                        <div className="option1">
                            <input type="checkbox" defaultChecked disabled={true} />
                            <label>Nezbytně nutné systémové cookies</label>
                        </div>
                    </div>
                    <button className="close-button-all" onClick={handleClose}>Přijmout</button>
                </div>
            </div>
        )
    );
}

export default CookieModal;
