
const Loader = () => {
    return(
        <div className={"loading-spinner"}>
            <div className={"loading-spinner_loader"}>
            </div>
            <img src="https://enigoo.cz/dist/img/logo-E.png" alt="ENIGOO"/>
        </div>
    )
}
export default Loader
